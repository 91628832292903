
import { defineComponent, ref } from 'vue';

import PaypalPayment from './PaypalPayment.vue';
import OnSitePayment from './OnSitePayment.vue';
import { PaymentMethod } from '@/hooks/orders/use-orders';

type Tab = { key: PaymentMethod; label: string };

export default defineComponent({
  components: { PaypalPayment, OnSitePayment },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  setup() {
    const tabs: Tab[] = [
      { key: 'paypal', label: 'Adresse de livraison' },
      { key: 'on-site', label: 'Récupérer sur place' },
    ];

    const activeTab = ref<PaymentMethod>(
      (localStorage.getItem('PAYMENT_METHOD') as PaymentMethod) || 'paypal'
    );

    const changeTab = (tab: PaymentMethod) => {
      if (activeTab.value === tab) return;

      activeTab.value = tab;
      localStorage.setItem('PAYMENT_METHOD', activeTab.value);
    };

    return {
      tabs,
      activeTab,
      changeTab,
    };
  },
});
