
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useMeta } from 'vue-meta';

import { checkoutMetaTags } from '@/lib/metaTags';
import CheckoutForm from '@/components/checkout/CheckoutForm.vue';
import Order from '@/components/checkout/Order.vue';
import useCart from '@/hooks/cart/use-cart';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  name: 'Checkout',
  components: { CheckoutForm, Order },
  setup() {
    useMeta(checkoutMetaTags());
    const router = useRouter();
    const { count } = useCart();
    const { currentlyOpen } = useBusinessHours();

    watch(count, (newCount) => {
      if (newCount <= 0) {
        router.replace({ name: 'Home' });
      }
    });

    return {
      currentlyOpen,
    };
  },
});
