import { Ref } from 'vue';

import { Order } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import useCart from '../cart/use-cart';
import formatOrder from '@/lib/formatOrder';
import { getOrdersState } from './use-orders';

export const useCreateOnSiteOrder: UseCreateOnSiteOrder = () => {
  const state = getOrdersState();
  const { items } = useCart();
  const { errors, loading, request } = useRequest({ authClient: true });

  const createOnSiteOrder = async (payload: OnSitePayload) => {
    const data = await request<Order>({
      url: `/orders/on-site`,
      method: 'POST',
      data: {
        ...payload,
        cartItems: items.value.map((item) => item.id),
      },
    });

    if (data) {
      state.orders.unshift(formatOrder(data));
    }
  };

  return { errors, loading, createOnSiteOrder };
};

export type UseCreateOnSiteOrder = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  createOnSiteOrder(payload: OnSitePayload): Promise<void>;
};

export type OnSitePayload = {
  phone: string;
};
