<template>
  <section class="min-h-[400px]">
    <h3 class="text-3xl font-display capitalize">Methode de livraison</h3>

    <div class="mt-4 flex items-center space-x-2 sm:space-x-4">
      <button
        class="
          py-3
          px-2
          tracking-wider
          border-b-2
          transition-all
          hover:border-primary
          focus:outline-none focus:ring-4
          sm:px-4
        "
        :class="[
          activeTab === tab.key
            ? 'border-primary font-bold text-gray-800 text-xs sm:text-base'
            : 'border-transparent text-gray-400 text-xs sm:text-sm',
        ]"
        type="button"
        v-for="tab in tabs"
        :key="tab.key"
        @click="changeTab(tab.key)"
      >
        {{ tab.label }}
      </button>
    </div>

    <paypal-payment :activeTab="activeTab" :phone="phone" />
    <on-site-payment :activeTab="activeTab" :phone="phone" />
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import PaypalPayment from './PaypalPayment.vue';
import OnSitePayment from './OnSitePayment.vue';
import { PaymentMethod } from '@/hooks/orders/use-orders';

type Tab = { key: PaymentMethod; label: string };

export default defineComponent({
  components: { PaypalPayment, OnSitePayment },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  setup() {
    const tabs: Tab[] = [
      { key: 'paypal', label: 'Adresse de livraison' },
      { key: 'on-site', label: 'Récupérer sur place' },
    ];

    const activeTab = ref<PaymentMethod>(
      (localStorage.getItem('PAYMENT_METHOD') as PaymentMethod) || 'paypal'
    );

    const changeTab = (tab: PaymentMethod) => {
      if (activeTab.value === tab) return;

      activeTab.value = tab;
      localStorage.setItem('PAYMENT_METHOD', activeTab.value);
    };

    return {
      tabs,
      activeTab,
      changeTab,
    };
  },
});
</script>
