<template>
  <div v-if="activeTab === 'on-site'">
    <section class="mt-9">
      <div class="mt-9">
        <AppButton
          type="button"
          class="w-full text-base tracking-wider uppercase bg-primary text-white"
          :loading="loading"
          @click="onCreateOrder"
        >
          Finaliser votre commande
        </AppButton>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import useToast from '@/hooks/use-toast';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';
import useCart from '@/hooks/cart/use-cart';
import useAuth from '@/hooks/auth/use-auth';
import useUser from '@/hooks/user/use-user';
import useOrders, { PaymentMethod } from '@/hooks/orders/use-orders';
import useCities from '@/hooks/cities/use-cities';

export default defineComponent({
  components: { AppButton },
  props: {
    activeTab: {
      type: String as PropType<PaymentMethod>,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showToast } = useToast();
    const { selectedCity } = useCities();
    const { isSignedIn, openAuthModal } = useAuth();
    const { shouldUpdateUserData, useUpdateUser } = useUser();
    const { updateUser } = useUpdateUser();
    const { clearCart } = useCart();
    const { currentlyOpen } = useBusinessHours();
    const { createOnSiteOrder, loading, errors } = useOrders().useCreateOnSiteOrder();

    const onCreateOrder = async () => {
      selectedCity.value = '';

      if (!currentlyOpen.value) {
        showToast({ message: 'Nous ne sommes pas ouverts pour le moment', type: 'failure' });
        return;
      }

      if (!isSignedIn.value) {
        openAuthModal('signIn');
        showToast({ message: 'Veuillez vous connecter pour continuer', type: 'failure' });
        return;
      }

      if (shouldUpdateUserData()) {
        updateUser({ phone: props.phone });
      }

      await createOnSiteOrder({ phone: props.phone });

      if (errors.value.length) {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      } else {
        showToast({ message: 'Votre commande est terminée' });
        clearCart();
      }
    };

    return {
      onCreateOrder,
      loading,
    };
  },
});
</script>
