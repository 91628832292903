import { reactive, toRefs, ToRefs } from 'vue';

import { Order, OrderItem } from '@/types/models';
import { useGetOrders, UseGetOrders } from './orders-get';
import { useCreateOnSiteOrder, UseCreateOnSiteOrder } from './order-onsite-create';
import { useCreatePaypalOrder, UseCreatePaypalOrder } from './order-paypal-create';
import { useCaptureOrder, UseCaptureOrder } from './order-capture';
import { useCancelOrder, UseCancelOrder } from './order-cancel';
import { useGetOrderItems, UseGetOrderItems } from './order-items-get';

const state: State = reactive({
  orders: [],
  ordersCount: undefined,
  orderItems: {},
});

export const getOrdersState = (): State => state;

const useOrders: UseOrders = () => ({
  ...toRefs(state),
  useGetOrders,
  useCreateOnSiteOrder,
  useCreatePaypalOrder,
  useCaptureOrder,
  useCancelOrder,
  useGetOrderItems,
});

export default useOrders;

type State = {
  orders: Order[];
  ordersCount?: number;
  orderItems: Record<string, OrderItem[]>;
};

type UseOrders = () => ToRefs<State> & {
  useGetOrders: UseGetOrders;
  useCreatePaypalOrder: UseCreatePaypalOrder;
  useCreateOnSiteOrder: UseCreateOnSiteOrder;
  useCaptureOrder: UseCaptureOrder;
  useCancelOrder: UseCancelOrder;
  useGetOrderItems: UseGetOrderItems;
};

export type PaymentMethod = 'paypal' | 'on-site';
