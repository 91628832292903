import { OrderItem } from '@/types/models';
import formatMoney from './formatMoney';
import { formatImage } from './formatProduct';

export default function formatOrderItem(item: OrderItem): OrderItem {
  const price = Number(item.price);
  const size = { ...item.size, price: Number(item.size.price) };

  return {
    ...item,
    formattedPrice: formatMoney(price),
    size: {
      ...size,
      formattedPrice: formatMoney(Number(size.price)),
    },
    image: formatImage(item.image),
  };
}
