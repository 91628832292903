
import { defineComponent, PropType, ref, watch } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import PaypalButton from './PaypalButton.vue';
import useCities from '@/hooks/cities/use-cities';
import useUser from '@/hooks/user/use-user';
import useOrders, { PaymentMethod } from '@/hooks/orders/use-orders';

export default defineComponent({
  components: { AppInput, PaypalButton },
  props: {
    activeTab: {
      type: String as PropType<PaymentMethod>,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { orders } = useOrders();
    const { cities, selectedCity, useGetCities } = useCities();
    const { getCities } = useGetCities();
    const { user } = useUser();

    const addressInfo = ref({
      postal_code: user.value?.postal_code || orders.value[0]?.postal_code || '',
      address_line1: user.value?.address_line1 || orders.value[0]?.address_line1 || '',
      address_line2: user.value?.address_line2 || orders.value[0]?.address_line2 || '',
    });

    watch(user, (newUser) => {
      // when user signs in keep their provided form data
      // and fill in the rest
      if (newUser) {
        addressInfo.value.postal_code = addressInfo.value.postal_code || newUser.postal_code || '';
        addressInfo.value.address_line1 =
          addressInfo.value.address_line1 || newUser.address_line1 || '';
        addressInfo.value.address_line2 =
          addressInfo.value.address_line2 || newUser.address_line2 || '';
      }
    });

    if (user.value?.city) {
      const city = cities.value.find(
        (city) => city.name === user.value?.city || city.name === orders.value[0]?.city
      );
      if (city) {
        selectedCity.value = city.name;
      }
    }

    getCities();

    return {
      addressInfo,
      selectedCity,
      cities,
    };
  },
});
