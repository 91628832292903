import { Ref } from 'vue';

import { Order } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import useCart from '../cart/use-cart';
import useCities from '../cities/use-cities';
import formatOrder from '@/lib/formatOrder';
import { getOrdersState } from './use-orders';

export const useCreatePaypalOrder: UseCreatePaypalOrder = () => {
  const state = getOrdersState();
  const { items } = useCart();
  const { cities, selectedCity } = useCities();
  const { errors, loading, request } = useRequest({ authClient: true });

  const createPaypalOrder = async (payload: PaypalPayload) => {
    const data = await request<{ orderId: string; order: Order }>({
      url: `/orders/paypal`,
      method: 'POST',
      data: {
        ...payload,
        cartItems: items.value.map((item) => item.id),
        cityId: cities.value.find((city) => city.name === selectedCity.value)?.id,
      },
    });

    if (data) {
      state.orders.unshift(formatOrder(data.order));
      return data.orderId;
    }
  };

  return { errors, loading, createPaypalOrder };
};

export type UseCreatePaypalOrder = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  createPaypalOrder(payload: PaypalPayload): Promise<string | undefined>;
};

export type PaypalPayload = {
  postal_code: string;
  address_line1: string;
  address_line2: string;
  phone: string;
};
