import { Ref } from 'vue';

import { Order } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import { getOrdersState } from './use-orders';
import formatOrder from '@/lib/formatOrder';

export const useCancelOrder: UseCancelOrder = () => {
  const state = getOrdersState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const cancelOrder = async (orderId: string) => {
    const data = await request<Order>({ url: `/orders/${orderId}`, method: 'DELETE' });

    if (data) {
      const index = state.orders.findIndex(({ id }) => id === data.id);

      if (index !== -1) {
        state.orders[index] = formatOrder(data);
      } else {
        state.orders.unshift(formatOrder(data));
      }
    }
  };

  return { errors, loading, cancelOrder };
};

export type UseCancelOrder = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  cancelOrder(orderId: string): Promise<void>;
};
