import { Ref } from 'vue';

import { Order } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import { getOrdersState } from './use-orders';
import formatOrder from '@/lib/formatOrder';

export const useCaptureOrder: UseCaptureOrder = () => {
  const state = getOrdersState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const captureOrder = async (orderId: string) => {
    const data = await request<Order>({
      url: `/orders/paypal/capture`,
      method: 'POST',
      data: { orderId },
    });

    if (data) {
      state.orders.unshift(formatOrder(data));
    }
  };

  return { errors, loading, captureOrder };
};

export type UseCaptureOrder = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  captureOrder(orderId: string): Promise<void>;
};
