<template>
  <section>
    <div id="card">
      <div
        class="
          h-4/5
          p-4
          fixed
          z-10
          bottom-0
          inset-x-0
          rounded-2xl
          bg-gray-50
          transition-transform
          duration-300
          md:bg-white
          lg:h-auto lg:p-0 lg:static lg:transform-none
        "
        :class="[isOpen ? 'translate-y-0' : 'translate-y-full']"
      >
        <h3 class="mb-4 text-2xl font-display capitalize sm:text-3xl md:mb-10">
          Votre commande ({{ itemsCount }})
        </h3>
        <div
          class="h-3/5 px-4 py-6 space-y-4 rounded-xl overflow-y-scroll bg-accent sm:max-h-[520px]"
        >
          <CartItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :useInternalEditOptionsModal="false"
            @on-open-options-modal="openEditOptionsModal"
          />
        </div>

        <div class="mt-10 space-y-4 flex flex-col justify-between">
          <div class="space-y-2">
            <div class="flex justify-between font-display">
              <span class="text-lg font-semibold capitalize">Sous-total:</span>
              <span class="text-lg">{{ formattedSubTotal }}</span>
            </div>
            <div class="flex justify-between font-display">
              <span class="text-lg font-semibold capitalize">Frais de livraison:</span>
              <span class="text-lg">{{ formattedShippementPrice }}</span>
            </div>
            <div class="flex justify-between font-body">
              <span class="text-xl font-semibold capitalize">total:</span>
              <span class="text-xl font-semibold">{{ totalPrice }}</span>
            </div>
          </div>
        </div>
      </div>

      <EditCartOptions ref="editOptionsModal" :item="modalCartItem || {}" />
    </div>

    <OrderButton v-model:isOpen="isOpen" />
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

import { CartItem as ICartItem } from '@/types/models';
import CartItem from '@/components/cart/CartItem.vue';
import EditCartOptions from '@/components/cart/EditCartOptions.vue';
import OrderButton from './OrderButton.vue';
import formatMoney from '@/lib/formatMoney';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import useCities from '@/hooks/cities/use-cities';
import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  components: { CartItem, OrderButton, EditCartOptions },
  setup() {
    const isOpen = ref(false);
    const { count, subTotal, items, formattedSubTotal } = useCart();
    const { shippementPrice, formattedShippementPrice } = useCities();
    const totalPrice = computed(() => formatMoney(subTotal.value + shippementPrice.value));
    const itemsCount = computed(
      () => `${count.value} ${count.value >= 1 ? 'articles' : 'article'}`
    );
    const modalCartItem = ref<ICartItem>();
    const editOptionsModal = ref<InstanceType<typeof EditCartOptions>>();

    const openEditOptionsModal = (cartItem: ICartItem) => {
      modalCartItem.value = cartItem;
      editOptionsModal.value?.popup?.open();
    };

    closeOnOutsideClick(isOpen, '#card');

    return {
      isOpen,
      count,
      subTotal,
      totalPrice,
      itemsCount,
      items,
      formattedSubTotal,
      formattedShippementPrice,
      editOptionsModal,
      modalCartItem,
      openEditOptionsModal,
    };
  },
});
</script>
