<template>
  <main class="max-w-screen-2xl mx-auto px-4 py-8 sm:py-12 lg:px-16">
    <h1 class="text-4xl font-heading md:text-5xl">Finaliser votre commande</h1>

    <div
      v-if="!currentlyOpen"
      class="px-2 py-3 mt-10 text-center rounded-md border border-red-400 text-gray-800"
    >
      <p class="text-sm md:text-base">
        Les commandes en ligne ne sont pas disponible pour le moment - Contactez nous par téléphone
        pour votre commande
      </p>
    </div>

    <div class="mt-10 grid grid-cols-5 gap-x-8 md:mt-20">
      <CheckoutForm class="col-span-5 lg:col-span-3" />
      <Order class="lg:col-span-2" />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useMeta } from 'vue-meta';

import { checkoutMetaTags } from '@/lib/metaTags';
import CheckoutForm from '@/components/checkout/CheckoutForm.vue';
import Order from '@/components/checkout/Order.vue';
import useCart from '@/hooks/cart/use-cart';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  name: 'Checkout',
  components: { CheckoutForm, Order },
  setup() {
    useMeta(checkoutMetaTags());
    const router = useRouter();
    const { count } = useCart();
    const { currentlyOpen } = useBusinessHours();

    watch(count, (newCount) => {
      if (newCount <= 0) {
        router.replace({ name: 'Home' });
      }
    });

    return {
      currentlyOpen,
    };
  },
});
</script>
