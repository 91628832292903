
import { computed, defineComponent, ref } from 'vue';

import { CartItem as ICartItem } from '@/types/models';
import CartItem from '@/components/cart/CartItem.vue';
import EditCartOptions from '@/components/cart/EditCartOptions.vue';
import OrderButton from './OrderButton.vue';
import formatMoney from '@/lib/formatMoney';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import useCities from '@/hooks/cities/use-cities';
import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  components: { CartItem, OrderButton, EditCartOptions },
  setup() {
    const isOpen = ref(false);
    const { count, subTotal, items, formattedSubTotal } = useCart();
    const { shippementPrice, formattedShippementPrice } = useCities();
    const totalPrice = computed(() => formatMoney(subTotal.value + shippementPrice.value));
    const itemsCount = computed(
      () => `${count.value} ${count.value >= 1 ? 'articles' : 'article'}`
    );
    const modalCartItem = ref<ICartItem>();
    const editOptionsModal = ref<InstanceType<typeof EditCartOptions>>();

    const openEditOptionsModal = (cartItem: ICartItem) => {
      modalCartItem.value = cartItem;
      editOptionsModal.value?.popup?.open();
    };

    closeOnOutsideClick(isOpen, '#card');

    return {
      isOpen,
      count,
      subTotal,
      totalPrice,
      itemsCount,
      items,
      formattedSubTotal,
      formattedShippementPrice,
      editOptionsModal,
      modalCartItem,
      openEditOptionsModal,
    };
  },
});
