import { Ref } from 'vue';

import { Order } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import { getOrdersState } from './use-orders';
import formatOrder from '@/lib/formatOrder';

export const useGetOrders: UseGetOrders = () => {
  const state = getOrdersState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const getOrders = async ({ start, limit }: Options = {}) => {
    if (state.ordersCount === undefined) {
      getOrdersCount();
    }

    if (start === undefined) {
      start = 0;
    }

    if (limit === undefined) {
      limit = 10;
    }

    const params = `_start=${start}&_limit=${limit}&_sort=created_at:desc`;

    const data = await request<Order[]>({ url: `/orders?${params}` });

    if (data) {
      state.orders = data.map(formatOrder);
    }
  };

  return { errors, loading, getOrders };
};

const getOrdersCount = async () => {
  const state = getOrdersState();
  const { request } = useRequest({ authClient: true });

  state.ordersCount = await request<number>({ url: `/orders/count` });
};

export type UseGetOrders = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  getOrders(options?: Options): Promise<void>;
};

type Options = {
  start?: number;
  limit?: number;
};
