import { Order } from '@/types/models';
import formatMoney from './formatMoney';

export default function formatOrder(order: Order): Order {
  const charge = Number(order.charge);
  const minimum_delivery_amount = Number(order.minimum_delivery_amount);
  const delivery_fee = Number(order.delivery_fee);

  return {
    ...order,
    charge,
    formattedCharge: formatMoney(charge),
    minimum_delivery_amount,
    formatted_minimum_delivery_amount: formatMoney(minimum_delivery_amount),
    delivery_fee,
    formatted_delivery_fee: formatMoney(delivery_fee),
  };
}
