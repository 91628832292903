<template>
  <button
    class="
      p-2
      fixed
      z-10
      bottom-2
      left-1/2
      -translate-x-1/2
      shadow-md
      rounded-full
      bg-white
      lg:hidden
    "
    :aria-label="isOpen ? 'fermez votre bon de commande' : 'ouvrez votre bon de commande'"
    @click.stop="$emit('update:isOpen', !isOpen)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 animate-pulse"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden
    >
      <path
        v-if="isOpen"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
      />
      <path
        v-else
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5 11l7-7 7 7M5 19l7-7 7 7"
      />
    </svg>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
