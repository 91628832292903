
import { defineComponent, ref, watch } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import useUser from '@/hooks/user/use-user';
import PaymentMethod from './PaymentMethod.vue';

export default defineComponent({
  components: { AppInput, PaymentMethod },
  setup() {
    const { user } = useUser();

    const personalInfo = ref({
      first_name: user.value?.first_name || '',
      last_name: user.value?.last_name || '',
      email: user.value?.email || '',
      phone: user.value?.phone || '',
    });

    watch(user, (newUser) => {
      // when user signs in keep their provided form data
      // and fill in the rest
      if (newUser) {
        personalInfo.value.first_name = personalInfo.value.first_name || newUser.first_name;
        personalInfo.value.last_name = personalInfo.value.last_name || newUser.last_name;
        personalInfo.value.email = personalInfo.value.email || newUser.email;
        personalInfo.value.phone = personalInfo.value.phone || newUser.phone || '';
      }
    });

    return {
      personalInfo,
    };
  },
});
