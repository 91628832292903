<template>
  <form class="space-y-14 lg:max-w-xl">
    <section>
      <h3 class="text-3xl font-display capitalize">Informations personnelles</h3>
      <div class="flex mt-9">
        <AppInput
          class="flex-1"
          id="firstname"
          label="Prénom"
          v-model="personalInfo.first_name"
          border="border border-gray-light
        rounded-bl-xl rounded-tl-xl"
        />
        <AppInput
          class="flex-1"
          id="lastname"
          label="Nom"
          v-model="personalInfo.last_name"
          border="rounded-br-xl rounded-tr-xl"
        />
      </div>

      <div class="flex mt-5 space-x-4">
        <AppInput
          class="flex-1"
          id="email"
          type="email"
          label="E-mail"
          v-model="personalInfo.email"
        />
        <AppInput class="flex-1" id="phone" label="Téléphone" v-model="personalInfo.phone" />
      </div>
    </section>

    <payment-method :phone="personalInfo.phone" />
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import useUser from '@/hooks/user/use-user';
import PaymentMethod from './PaymentMethod.vue';

export default defineComponent({
  components: { AppInput, PaymentMethod },
  setup() {
    const { user } = useUser();

    const personalInfo = ref({
      first_name: user.value?.first_name || '',
      last_name: user.value?.last_name || '',
      email: user.value?.email || '',
      phone: user.value?.phone || '',
    });

    watch(user, (newUser) => {
      // when user signs in keep their provided form data
      // and fill in the rest
      if (newUser) {
        personalInfo.value.first_name = personalInfo.value.first_name || newUser.first_name;
        personalInfo.value.last_name = personalInfo.value.last_name || newUser.last_name;
        personalInfo.value.email = personalInfo.value.email || newUser.email;
        personalInfo.value.phone = personalInfo.value.phone || newUser.phone || '';
      }
    });

    return {
      personalInfo,
    };
  },
});
</script>
