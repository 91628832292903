import { Ref } from 'vue';

import { City } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getCitiesState } from './use-cities';

export const useGetCities: UseGetCities = () => {
  const state = getCitiesState();
  const { errors, loading, request } = useRequest();

  const getCities = async () => {
    const data = await request<City[]>({ url: `/cities` });
    if (data) {
      state.cities = data;
    }
  };

  return { errors, loading, getCities };
};

export type UseGetCities = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  getCities(): Promise<void>;
};
