import { computed, reactive, ToRefs, toRefs } from 'vue';

import { City } from '@/types/models';
import { useGetCities, UseGetCities } from './cities-get';
import formatMoney from '@/lib/formatMoney';

const state: State = reactive({
  cities: [],
  selectedCity: '',
  shippementPrice: computed(() => {
    if (!state.selectedCity) return 0;

    const city = state.cities.find(
      (item) => item.name.toLowerCase() === state.selectedCity.toLowerCase()
    );

    if (!city) return 0;

    return Number(city.delivery_fee);
  }),
  formattedShippementPrice: computed(() => {
    return formatMoney(Number(state.shippementPrice));
  }),
});

export const getCitiesState = (): State => state;

const useCities: UseCities = () => {
  return {
    ...toRefs(state),
    useGetCities,
  };
};

export default useCities;

type State = {
  cities: City[];
  selectedCity: string;
  shippementPrice: number;
  formattedShippementPrice: string;
};

type UseCities = () => ToRefs<State> & {
  useGetCities: UseGetCities;
};
