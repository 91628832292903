<template>
  <div class="relative z-10">
    <div id="paypal-button-container"></div>
    <div v-if="loadingPaypal" class="flex justify-center">
      <svg
        class="animate-spin -ml-1 mr-3 h-6 w-6 text-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        aria-label="Loading..."
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { loadScript, PayPalNamespace } from '@paypal/paypal-js';

import { ResponseError } from '@/lib/formatErrors';
import { PaypalPayload } from '@/hooks/orders/order-paypal-create';
import { getPaypalClientId } from '@/lib/env';
import useToast from '@/hooks/use-toast';
import useCart from '@/hooks/cart/use-cart';
import useCities from '@/hooks/cities/use-cities';
import useOrders from '@/hooks/orders/use-orders';
import useAuth from '@/hooks/auth/use-auth';
import useUser from '@/hooks/user/use-user';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  props: {
    address: {
      type: Object as PropType<PaypalPayload>,
      required: true,
    },
  },
  setup(props) {
    const loadingPaypal = ref(false);
    const { showToast } = useToast();
    const { isSignedIn, openAuthModal } = useAuth();
    const { shouldUpdateUserData, useUpdateUser } = useUser();
    const { updateUser } = useUpdateUser();
    const { clearCart } = useCart();
    const { selectedCity } = useCities();
    const { useCreatePaypalOrder, useCaptureOrder, useCancelOrder } = useOrders();
    const { createPaypalOrder, errors: createErrors } = useCreatePaypalOrder();
    const { captureOrder, errors: caputreErrors } = useCaptureOrder();
    const { cancelOrder, errors: cancelErrors } = useCancelOrder();
    const { currentlyOpen } = useBusinessHours();

    const setPaypal = () => {
      loadingPaypal.value = true;

      loadScript({
        'client-id': getPaypalClientId(),
        'disable-funding': 'credit,card',
        currency: 'EUR',
      })
        .then((payapl) => {
          loadingPaypal.value = false;
          renderButton(payapl);
        })
        .catch(() => {
          showToast({ message: 'failed to load the PayPal JS SDK script', type: 'failure' });
        });
    };

    const renderButton = (paypal: PayPalNamespace | null) => {
      if (!paypal || !paypal.Buttons) return;

      paypal
        .Buttons({
          onClick(_, actions) {
            if (!currentlyOpen.value) {
              showToast({ message: 'Nous ne sommes pas ouverts pour le moment', type: 'failure' });
              return actions.reject();
            }

            if (!isSignedIn.value) {
              openAuthModal('signIn');
              showToast({ message: 'Veuillez vous connecter pour continuer', type: 'failure' });
              return actions.reject();
            }

            if (!selectedCity.value) {
              showToast({ message: 'Vous devez choisir une ville', type: 'failure' });
              return actions.reject();
            }
          },
          async createOrder() {
            // update the user address with the given
            if (shouldUpdateUserData()) {
              updateUser({ ...props.address, city: selectedCity.value });
            }

            const orderId = await createPaypalOrder({
              postal_code: props.address.postal_code,
              address_line1: props.address.address_line1,
              address_line2: props.address.address_line2,
              phone: props.address.phone,
            });

            if (!orderId) {
              return Promise.reject(createErrors.value);
            }

            return orderId;
          },
          async onApprove(data) {
            await captureOrder(data.orderID);

            if (caputreErrors.value.length) {
              cancelOrder(data.orderID as string);
              return Promise.reject(caputreErrors.value);
            }

            showToast({ message: 'Votre commande est terminée' });
            clearCart();
            selectedCity.value = '';
          },
          onError(error) {
            if (Array.isArray(error)) {
              error.forEach((err: ResponseError) =>
                showToast({ message: err.message, type: 'failure' }, 10000)
              );
            } else {
              showToast({ message: error.message as string, type: 'failure' }, 10000);
            }
          },
          async onCancel(data) {
            await cancelOrder(data.orderID as string);

            if (cancelErrors.value.length) {
              return Promise.reject(caputreErrors.value);
            } else {
              showToast({ message: 'Commande annulée' });
            }
          },
        })
        .render('#paypal-button-container');
    };

    onMounted(setPaypal);

    return {
      loadingPaypal,
    };
  },
});
</script>
