import { Ref } from 'vue';

import { OrderItem } from '@/types/models';
import { useRequest } from '@/lib/http';
import { ResponseError } from '@/lib/formatErrors';
import { getOrdersState } from './use-orders';
import formatOrderItem from '@/lib/formatOrderItem';

export const useGetOrderItems: UseGetOrderItems = () => {
  const state = getOrdersState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const getOrderItems = async (orderId: number) => {
    const cacheKey = `order:${orderId}`;
    if (state.orderItems[cacheKey]) return state.orderItems[cacheKey];

    const data = await request<OrderItem[]>({ url: `/order-items?order=${orderId}` });

    if (!data) {
      return [];
    }

    state.orderItems[cacheKey] = data.map(formatOrderItem);
    return state.orderItems[cacheKey];
  };

  return { errors, loading, getOrderItems };
};

export type UseGetOrderItems = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  getOrderItems(orderId: number): Promise<OrderItem[]>;
};
